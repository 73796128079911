<template>
	<div class="swiper-container">
		<div class="swiper-wrapper">
			<div class="swiper-slide" v-for="item in bannerList" :key="item.id">
				<img :src="item.src" :title="item.title" />
			</div>
		</div>
		<!-- 如果需要分页器 -->
		<div class="swiper-pagination"></div>

		<!-- 如果需要导航按钮 -->
		<!-- <div class="swiper-button-prev"></div> -->
		<!-- <div class="swiper-button-next"></div> -->

		<!-- 如果需要滚动条 -->
		<!-- <div class="swiper-scrollbar"></div> -->
	</div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
	data() {
		return {
			bannerList: []
		}
	},
	methods: {
		async onChangeSwiper() {
			const that = this
            this.$nextTick(()=>{
                new Swiper('.swiper-container', {
                    // direction: 'vertical', // 垂直切换选项
                    effect: 'coverflow',
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 0,
                        depth: 80, // 图片两边的视距
                        modifier: 1, // depth和rotate和stretch的倍率
                        slideShadows: false // 是否开启slide阴影
                    },
                    loop: true, // 循环模式选项
                    observer: true, //开启动态检查器，监测swiper和slide
                    // allowTouchMove: false, //设置是否允许触摸滑动
                    autoplay: false,
                    // 如果需要分页器
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'fraction'
                    },
                    on: {
                        // 如果进度条未加载，在init 调用 that.onChangeWidth()
                        init: () => {},
                        // 过渡开始时触发。可选Swiper实例作为参数，会首次加载一次
                        transitionStart: () => {}
                    },
                    // 如果需要前进后退按钮
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    // 如果需要滚动条
                    scrollbar: {
                        el: '.swiper-scrollbar'
                    }
                })
            })
		}
	}
}
</script>

<style lang="scss" scoped>
.swiper-container {
	cursor: pointer;
	width: 100%; // 改成移动端尺寸
	height: 370px;
}

.swiper-slide {
	width: 80%;
	height: 100%;
	background-position: center;
	background-size: cover;
	> img {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.swiper-slide-prev,
.swiper-slide-next {
	> img {
		opacity: 0.6;
	}
}

.swiper-pagination {
	left: 50%;
    bottom: 20px;
	transform: translateX(-50%);
    width: 80px;
    padding: 4px 4px;
    font-size: 24px;
    color: #fff;
    background: rgba($color: #000000, $alpha: 0.5);
    border-radius: 4px;
    z-index: 999;
}
</style>
