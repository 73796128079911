<template>
  <div class="container">
    <div class="banner">
      <img :src="brand?.details?.banner" />
    </div>
    <div class="about">
      <div class="navtitle">
        <div class="title">{{ brand.title }}</div>
        <div class="horizontal_line"></div>
      </div>
      <p>{{ brand.intro }}</p>
      <fold-swiper ref="swiper" v-if="showSwiper" />
    </div>
    <div class="advantage">
      <div class="navtitle">
        <div class="title" style="color: #fff">核心优势</div>
        <div class="horizontal_line"></div>
      </div>
      <div class="adv_list">
        <div
          class="adv_list_item"
          v-for="(item, index) in brand?.details?.adv"
          :key="index"
        >
          <img :src="item.icon" />
          <div class="item_right">
            <span class="title">{{ item.title }}</span>
            <span class="intro">{{ item.intro }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="exhibit">
      <div class="navtitle">
        <div class="title">房型展示</div>
        <div class="horizontal_line"></div>
      </div>
      <div class="room_list">
        <div
          class="room_list_item"
          v-for="item in house_types"
          :key="item.fx_id"
          @click="toHouseDetail(item.fx_id)"
        >
          <img v-lazy="item?.cover_picture?.path" />
          <div class="info">
            <span class="title">{{ item.fx_name }}</span>
            <div class="area">
              <span>{{ item.acreage_min }}-{{ item.acreage_max }}㎡</span>
              <span>|</span>
              <span>{{ item.rental_type == 1 ? "整租" : "合租" }}</span>
            </div>
            <div class="price">
              <span class="num">{{ item.price_min }}-{{ item.price_max }}</span>
              <span>元/月</span>
            </div>
            <div class="tag_list">
              <div
                class="tag_list_item"
                v-for="(item2, index) in item.appliances"
                :key="index"
              >
                {{ item2.bq_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="facilities">
      <div class="navtitle">
        <div class="title">基础设施</div>
        <div class="horizontal_line" style="background: #eee"></div>
      </div>
      <div class="facilities_list">
        <div
          class="facilities_list_item"
          v-for="(item, index) in iconList"
          :key="index"
        >
          <img :src="require(`@/assets/icon/${item.path}.png`)" />
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <mobile-Loading v-if="isLoading" />
  </div>
</template>
<script>
import foldSwiper from "../components/swiper/foldSwiper.vue";
import { getBrandDetail } from "@/api/brand";
export default {
  name: "brandDetail",
  components: {
    foldSwiper,
  },
  data() {
    return {
      iconList: [
        { name: "安全监控", path: "aqjk" },
        { name: "WIFI", path: "wifi2" },
        { name: "便利店", path: "bld" },
        { name: "停车场", path: "tcc" },
        { name: "活动场地", path: "hdcd" },
        { name: "电梯", path: "dt" },
        { name: "地铁", path: "ditie" },
        { name: "休闲区", path: "xxq" },
        { name: "门禁", path: "mj" },
        { name: "服务维修", path: "fwwx" },
        { name: "娱乐", path: "yule" },
        { name: "安保", path: "anbao" },
        { name: "健身房", path: "jsf" },
        { name: "公园", path: "gy" },
      ],
      brand: {},
      house_types: [],
      showSwiper: true,
      isLoading: true,
    };
  },
  methods: {
    async initData(id) {
      try {
        const { data: res } = await getBrandDetail(id);
        this.brand = res.brand;
        this.house_types = res.house_types;
        //轮播图
        this.$refs.swiper.bannerList = res?.brand?.details?.images;
        this.$refs.swiper.onChangeSwiper();
      } finally {
        this.isLoading = false;
      }
    },
    toHouseDetail(id) {
      this.$router.push(`/houseDetail?id=${id}`);
    },
  },
  watch: {
    $route: {
      handler(n) {
        this.initData(n.query.id);
        this.showSwiper = false;
        this.$nextTick(() => {
          this.showSwiper = true;
        });
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped lang="scss">
.navtitle {
  .title {
    color: #ffa71d;
    font-size: 36px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .horizontal_line {
    width: 150px;
    height: 2px;
    background-color: #eeeeee;
    position: relative;
    margin: 24px auto;
    &::after {
      position: absolute;
      content: "";
      width: 40px;
      height: 4px;
      background-color: #ffa71d;
      left: 50%;
      top: -1px;
      transform: translateX(-50%);
    }
  }
}
.container {
  .banner {
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
  .about {
    padding: 50px 30px;
    box-sizing: border-box;
    p {
      color: #666666;
      font-size: 28px;
      text-indent: 2em;
      line-height: 40px;
      margin: 30px 0;
    }
  }
  .advantage {
    padding: 50px 30px;
    box-sizing: border-box;
    background: #ffa71d;
    .horizontal_line {
      background: rgba($color: #fff, $alpha: 0.3);
      &::after {
        background: #fff;
      }
    }
    .adv_list {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      margin-top: 20px;
      &_item {
        width: 100%;
        height: 200px;
        background: #fff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        column-gap: 23px;
        padding: 44px 29px;
        box-sizing: border-box;
        img {
          border-radius: 50%;
          width: 112px;
          height: 112px;
        }
        .item_right {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          .title {
            color: #333;
            font-size: 28px;
            font-weight: bold;
          }
          .intro {
            color: #999999;
            font-size: 24px;
          }
        }
      }
    }
  }
  .exhibit {
    padding: 50px 30px;
    box-sizing: border-box;
    .room_list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin-top: 20px;
      &_item {
        border: 1px solid #eeeeee;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: 240px;
          object-fit: cover;
        }
        .info {
          padding: 16px;
          box-sizing: border-box;
          color: #999;
          font-size: 22px;
          .title {
            color: #333333;
            font-size: 28px;
            font-weight: bold;
          }
          .area {
            margin: 18px 0;
            display: flex;
            column-gap: 20px;
            align-items: center;
          }
          .price {
            color: #666666;
            font-size: 24px;
            .num {
              color: #ff4d4d;
              font-size: 32px;
              font-weight: bold;
            }
          }
          .tag_list {
            display: flex;
            column-gap: 12px;
            row-gap: 12px;
            flex-wrap: wrap;
            margin-top: 16px;
            &_item {
              padding: 6px 8px;
              box-sizing: border-box;
              border: 1px solid #d9d9d9;
              border-radius: 4px;
              background: #f4f4f4;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
  .facilities {
    padding: 50px 30px;
    box-sizing: border-box;
    background: #f5f5f5;
    .facilities_list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 39px;
      margin-top: 20px;
      &_item {
        font-size: 24px;
        color: #666666;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 18px;
        img {
          width: 84px;
          height: 84px;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
